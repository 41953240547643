import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import ActiveItemGallery from "@components/ActiveItemGallery"

import { VideoPlayer } from "@components/video"

import {
  ScrollToButton,
} from "@components/buttons"

import {
  RequestMoreInfo,
} from "@components/Forms"

const galleryItems = [
  {
    key: 1,
    title: "LP-style Custom Guitar",
    images: ["/images/guitar007-futura-lp-style.jpg"],
  },
  {
    key: 2,
    title: "Body Front",
    images: ["/images/guitar007-futura-front.jpg"],
  },
  {
    key: 3,
    title: "Body Back",
    images: ["/images/guitar007-futura-back.jpg"],
  },
  {
    key: 4,
    title: "Raised Center",
    images: ["/images/guitar007-futura-raisedcenter.jpg"],
  },
  {
    key: 5,
    title: "Binding",
    images: ["/images/guitar007-futura-binding.jpg"],
  },
  {
    key: 6,
    title: "Reversed Headstock",
    images: ["/images/guitar007-futura-headstock.jpg"],
  },
  {
    key: 7,
    title: "Fretboard",
    images: ["/images/guitar007-futura-fretboard.jpg"],
  },
  {
    key: 8,
    title: "Fretboard",
    images: ["/images/guitar007-futura-trussrod.jpg"],
  },
  {
    key: 9,
    title: "Output",
    images: ["/images/guitar007-futura-output.jpg"],
  },
  {
    key: 10,
    title: "Electronics",
    images: ["/images/guitar007-futura-electronics.jpg"],
  },
  {
    key: 11,
    title: "Builder Photo",
    images: ["/images/guitar007-futura-randy.jpg"],
  },
]

const Guitar007 = () => {
  return (
    <PageLayout>
      <SEO
        title="LP-Style Futura | Humbucker Size P90 Pickups | Spanish Cedar"
        ogDescription="This LP-style guitar is made of Spanish cedar with dual P-90 pickups that sing. Let's build your dream guitar!"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div className="content">
            <h1>LP-Style Custom Guitar</h1>
            <p className="detail">
              <b>Guitar:</b> Futura #1
            </p>
            <p className="detail">
              <b>Finish:</b> Spanish Cedar natural burst
            </p>
            <p className="detail">
              <b>Details:</b> LP-Style body with humbucker size dual P90 Bootstrap pickups
            </p>
            <ScrollToButton
              id="videoone"
              content="Watch Videos"
              style={{ marginBottom: "20px" }}
            />
          </div>
        }
      />
      <ActiveItemGallery
        items={galleryItems}
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>This Guitar's Story</h2>
            <p>
              After designing and building my verion of a T-style guitar (the <a href="/t-style-guitar-taurus/">Taurus</a>), I turned my attention to designing a single-cut LP-style guitar with my own flair. I used design cues from several classic guitars. But I also went back to my childhood pulling influence from my dad's 1963 Ford Falcon Futura.
            </p>
            <p>
              I built this particular guitar for my brother, and it became the proving ground for features that are now standard for the Futura model. This is also the first guitar with my Memorial Finish. This finish technique incorporates the ashes of a loved one into the pores of the wood. Then a urethane top coat is applied.
            </p>
            <p>
              My goal for the Futura was a sleek looking, light-weight guitar with great playability. As you can see in the videos below, that goal was met!
            </p>
            <h2>Specifications</h2>
            <ul>
              <li>7-piece vertical-grain Spanish Cedar, Peruvian Walnut, and Maple Neck</li>
              <li>Bocote Fretboard</li>
              <li>Black binding on Fretboard and Headstock</li>
              <li>25-inch Scale</li>
              <li>12-inch Fretboard Radius</li>
              <li>Medium Cryowire Frets</li>
              <li>Dual Action Truss Rod with spoke adjustment in fretboard</li>
              <li>Hybrid Bolt-on Neck with Set-neck Style Pocket Secured with Inserts and Stainless Steel Machine Screws</li>
              <li>No Fretboard Inlay (Inlay Delete option)</li>
              <li>White Side-Dot Inlays</li>
              <li>Blqck Bone Nut</li>
              <li>Hipshot Open Gear Locking Tuners</li>
              <li>7-piece Spanish Cedar, Peruvian Walnut, and Maple Body</li>
              <li>Black Body Binding</li>
              <li>Rasided Center Section with Black Binding</li>
              <li>Contoured Neck Heel Access and Belly Carve</li>
              <li>Memorial Finish with Custom Tinted Burst</li>
              <li>Magnetic Control Cavity Cover with Finger Access</li>
              <li>Gloss Urethane Topcoat with Matte on Neck</li>
              <li>Hipshot String Through Body Hardtail Bridge</li>
              <li>Bootstrap Humbucker Sized "Sweaky Clean" P90 Pickups</li>
              <li>CTS 500k Posts (2 Volume, 1 Tone)</li>
              <li>Switchcraft 3-Way Toggle Switch</li>
              <li>.022uf Orange Drop Cap</li>
              <li>Switchcraft Input with Electrosocket Mount</li>
            </ul>
            <h2>Price and Availability</h2>
            <p className="red">
              <strong>SOLD</strong>
            </p>
          </div>
        }
      />
      <div className="section textured" id="videoone">
          <VideoPlayer youtubeId='DVuWXmwAk9A' />
          <VideoPlayer youtubeId='GUZrCoLVwO4' />
          <VideoPlayer youtubeId='CN3pwhMtnLk' />
      </div>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Want me to build you something like it? Let's talk!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
      
    </PageLayout>
  )
}

export default Guitar007